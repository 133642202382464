import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { CredentialsStore } from './credentialsSlice';
import { User } from '@/types/user';

type RootState = {
  credentials: CredentialsStore;
};

const selectUser = (state: RootState) => state.credentials.user;
const selectToken = (state: RootState) => state.credentials.accessToken;
const selectIsUserLoggedIn = createSelector(
  [selectToken],
  (accessToken) => !!accessToken,
);

export const useCurrentUser: () => User | undefined = () =>
  useSelector(selectUser);
export const useIsUserLoggedIn: () => boolean = () =>
  useSelector(selectIsUserLoggedIn);
