import { menuList } from '@/constants/menuList';
import { ReactElement } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button } from '../ui/button';

export const MenuButtons = (): ReactElement => {
  const location = useLocation();
  return (
    <div className="flex flex-col">
      {menuList.map(({ name, href }) => {
        return (
          <Button
            key={name}
            asChild
            variant="ghost"
            className={`${
              location.pathname === href ? 'bg-slate-100' : ''
            } justify-start hover:bg-slate-100`}
          >
            <Link to={href}>{name}</Link>
          </Button>
        );
      })}
    </div>
  );
};
