'use client';

import { Checkbox } from '@/components/ui/checkbox';
import { FormRequestType } from '@/types/formRequestType';
import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';
import { ArrowUpDown } from 'lucide-react';
import { Button } from '../ui/button';
import { capitalize } from 'lodash';

const optionsMap: Record<string, string> = {
  'e-commerce': 'E-commerce',
  'mobile-apps': 'Mobile Apps',
  'web-apps': 'Web Apps',
  'web-page': 'Web Page',
  'custom-software': 'Custom Software',
  saas: 'SaaS',
  'ux-ui': 'UX/UI',
  'do-not-know': 'Do Not Know',
};

export const columns: ColumnDef<FormRequestType>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'name',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Name
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },
  {
    accessorKey: 'email',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
        >
          Email
          <ArrowUpDown className="ml-2 h-4 w-4" />
        </Button>
      );
    },
  },

  {
    accessorKey: 'message',
    header: 'Message',
  },
  {
    accessorKey: 'options',
    header: 'Options',
    accessorFn: ({ options }) => {
      return options.map((option) => optionsMap[option]).join(', ');
    },
  },
  {
    accessorKey: 'Date',
    accessorFn: ({ createdAt }) => format(new Date(createdAt), 'dd/MM/yyyy'),
  },
];
