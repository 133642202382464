import { useGetFormRequestsQuery } from '@/api/form';
import { ReactElement } from 'react';
import { DataTable } from './DataTable';
import { columns } from './columns';

export const FormRequests = (): ReactElement => {
  const { data, error } = useGetFormRequestsQuery();

  return (
    <div className="w-full px-10">
      <h2 className="py-10 text-2xl font-semibold leading-loose">
        Form requests
      </h2>
      {data && <DataTable columns={columns} data={data?.formRequests ?? []} />}
    </div>
  );
};
