import { ReactElement } from 'react';
import { Avatar, AvatarImage } from '../ui/avatar';
import { useCurrentUser } from '@/slices/credentials/selectors';
import capitalize from 'lodash/capitalize';

export const MenuAvatar = (): ReactElement => {
  const user = useCurrentUser()!;
  return (
    <div className="flex items-center gap-x-2 text-sm p-3">
      <Avatar>
        <AvatarImage src="https://github.com/yetiasg.png" />
      </Avatar>
      <div className="flex flex-col">
        {user && (
          <h2 className="font-medium">{`${user.firstName} ${user.lastName}`}</h2>
        )}
        {user && <p>{capitalize(user.role)}</p>}
      </div>
    </div>
  );
};
