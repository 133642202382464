import logo from '@/assets/logo.webp';
import { useLogout } from '@/hooks/useLogout';
import { ReactElement } from 'react';
import { Button } from '../ui/button';
import { MenuAvatar } from './MenuAvatar';
import { MenuButtons } from './MenuButtons';

export const Menu = (): ReactElement => {
  const logout = useLogout();

  return (
    <nav className="flex flex-col justify-between p-6 w-64 bg-white border-r border-slate-200">
      <div className="flex flex-col gap-y-6">
        <a href="/">
          <img src={logo} className="w-24.5" />
        </a>
        <MenuAvatar />
        <MenuButtons />
      </div>
      <div className="flex flex-col">
        <Button variant="ghost" className="justify-start hover-bg-slate-100">
          Ustawienia
        </Button>
        <Button
          variant="ghost"
          className="justify-start text-red-500 hover:text-red-500 hover-bg-slate-100"
          onClick={logout}
        >
          Wyloguj
        </Button>
      </div>
    </nav>
  );
};
