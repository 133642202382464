import google from '@/assets/google.svg';
import logo from '@/assets/logo.webp';
import { useAuth0 } from '@auth0/auth0-react';
import { AlertCircle } from 'lucide-react';
import { ReactElement, useCallback } from 'react';
import { Alert, AlertDescription, AlertTitle } from '../ui/alert';
import { Button } from '../ui/button';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '../ui/card';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIsUserLoggedIn } from '@/slices/credentials/selectors';

export const Login = (): ReactElement => {
  const { logout, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isUserLoggedIn = useIsUserLoggedIn();
  if (isUserLoggedIn) navigate('/form-requests');

  const handleOAuthLogin = useCallback(async () => {
    try {
      await loginWithRedirect();
    } catch (error: any) {
      console.error('Auth0 login error', error);
      await logout();
    }
  }, [dispatch, loginWithRedirect, logout]);

  return (
    <section className="flex justify-center items-center h-screen w-screen bg-slate-50">
      <Card className="max-w-100 p-10">
        <img src={logo} className="mx-auto pt-6 w-64" />
        {/* <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Coś poszło nie tak</AlertTitle>
          <AlertDescription>
            Twoje konto nie zostało znalezione na liście użytkowników dostępu do
            aplikacji
          </AlertDescription>
        </Alert> */}
        <CardHeader className="px-0">
          <CardTitle>Zaloguj się na swoje konto</CardTitle>
          <CardDescription>
            Aby mieć dostęp do aplikacji wystarczy, że zalogujesz się za pomocą
            konta Google
          </CardDescription>
        </CardHeader>
        <CardContent className="px-0">
          <Button
            onClick={handleOAuthLogin}
            className="flex items-center gap-x-2 w-full"
          >
            <img src={google} />
            <p>Zaloguj się za pomocą Google</p>
          </Button>
        </CardContent>
      </Card>
    </section>
  );
};
