import { api } from '@/api/api';
import { logout } from '@/slices/credentials/credentialsSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useLogout = () => {
  const dispatch = useDispatch();
  const { isAuthenticated, logout: auth0Logout } = useAuth0();

  return useCallback(async () => {
    dispatch(api.util.resetApiState());
    dispatch(logout());
    await auth0Logout();
  }, [dispatch, isAuthenticated]);
};
