import { User } from '@/types/user';
import { api } from './api';

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<{ user: User }, void>({
      providesTags: (result) =>
        result ? [{ id: result.user.id, type: 'User' as const }] : [],
      query: () => `/users/current`,
    }),
  }),
  overrideExisting: false,
});

export const userEndpoints = usersApi.endpoints;
export const { useGetCurrentUserQuery } = usersApi;
