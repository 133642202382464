import { MenuListType } from '@/types/menuListType';

export const menuList: MenuListType[] = [
  {
    name: 'Projects',
    href: '/projects',
  },
  {
    name: 'Clients',
    href: '/clients',
  },
  {
    name: 'Form requests',
    href: '/form-requests',
  },
  {
    name: 'Coworkers',
    href: '/coworkers',
  },
];
