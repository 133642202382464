import { createBrowserRouter } from 'react-router-dom';
import { App } from './App';
import { FormRequests } from './components/formRequests/FormRequests';
import { Login } from './components/login/Login';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <FormRequests />,
      },
      {
        path: 'form-requests',
        element: <FormRequests />,
      },
      {
        path: 'coworkers',
        element: <p>Coworkers</p>,
      },
      {
        path: 'clients',
        element: <p>Clients</p>,
      },
      {
        path: 'projects',
        element: <p>Projects</p>,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
]);
