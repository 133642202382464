import { FormRequestType } from '@/types/formRequestType';
import { api } from './api';

const baseUrl = '/form-requests';

const formRequestApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFormRequests: builder.query<{ formRequests: FormRequestType[] }, void>({
      providesTags: ['FormRequest'],
      query: () => baseUrl,
    }),
    deleteFormRequest: builder.mutation<FormRequestType, string>({
      query: (id) => ({
        url: `${baseUrl}/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'FormRequest' }],
    }),
  }),
  overrideExisting: false,
});

export const formRequestsEndpoints = formRequestApi.endpoints;
export const { useGetFormRequestsQuery, useDeleteFormRequestMutation } =
  formRequestApi;
